import classNames from "classnames";
import { getPercentage } from "shared/utils";

const ProgressBar = ({
  percentage,
  barClassName,
}: {
  percentage: number;
  barClassName: string;
}) => {
  return (
    <div className="h-2 w-full rounded-full bg-tixy-900">
      <div
        className={classNames("h-2 rounded-full bg-tixy-500", barClassName)}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

interface StatsSummaryProps {
  allTicketsCount: number;
  scannedTicketsCount: number;
}

export const StatsSummary = ({
  allTicketsCount,
  scannedTicketsCount,
}: StatsSummaryProps) => {
  const percentage = getPercentage(scannedTicketsCount, allTicketsCount);

  return (
    <div className="bg-tixy-950 px-4 pb-6 pt-4 rounded-lgplus flex flex-col gap-2.5">
      <h3 className="font-medium text-stone-400">Wszystkie bilety</h3>
      <div>
        <p className="font-bold text-white text-2xl">
          {scannedTicketsCount} zeskanowanych
        </p>
        <p className="text-stone-500">
          {percentage}% z {allTicketsCount}
        </p>
      </div>
      <ProgressBar percentage={percentage} barClassName="bg-tixy-500" />
    </div>
  );
};
