import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
} from "react";
import { useParams } from "react-router";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { tokenPublicKey } from "../envVariables";
import { parseToken, validateToken } from "../token";
import { ScannerTokenData } from "../types";

type ContextValues = {
  isScannerBlocked: boolean;
  setScannerBlocked: Dispatch<boolean>;
  isScannerTokenValid: boolean;
  scannerToken?: string;
  eventId?: string;
  scanningName?: string;
  scannerId?: string;
};

const ScannerContext = createContext<ContextValues>({
  isScannerBlocked: false,
  setScannerBlocked: () => {},
  isScannerTokenValid: false,
  scannerToken: "",
  eventId: "",
  scanningName: "",
  scannerId: "",
});

type Props = {
  children: ReactNode;
};

export const ScannerProvider = ({ children }: Props) => {
  const { scannerToken } = useParams<{ scannerToken: string }>();
  const [isScannerTokenValid] = useState(() =>
    scannerToken ? validateToken(scannerToken, tokenPublicKey) : false,
  );
  const [isScannerBlocked, setScannerBlocked] = useState(false);
  const tokenData = useMemo<ScannerTokenData | undefined>(
    () =>
      isScannerTokenValid
        ? parseToken<ScannerTokenData>(scannerToken!)
        : (undefined as any),
    [scannerToken, isScannerTokenValid],
  );
  const eventId = tokenData?.payloadObj.event;
  const scanningName = tokenData?.payloadObj.name;
  const [scannerId] = tokenData?.payloadObj.scannerId.split("-") ?? [];

  return (
    <ScannerContext.Provider
      value={{
        isScannerBlocked,
        setScannerBlocked,
        isScannerTokenValid,
        scannerToken,
        eventId,
        scanningName,
        scannerId,
      }}
    >
      {isScannerBlocked ? (
        <div className="sticky mb-2 text-center p-2 top-0 z-50 w-screen min-h-6 bg-ruby-650 -ml-4 -mt-4 text-sm">
          <ExclamationTriangleIcon className="h-5 w-5 mr-2 inline-block align-middle" />
          <span className="shrink">
            Skaner został zablokowany. Skontaktuj się z organizatorem
            wydarzenia.
          </span>
        </div>
      ) : null}
      {children}
    </ScannerContext.Provider>
  );
};

export const useScannerConfig = () => useContext(ScannerContext);
